/* Wrappers */

.ant-layout.page-wrapper {
  padding: 20px 20px 20px 0;
  background-color: #0061d4;
}

.ant-layout.ant-layout-has-sider > .ant-layout {
  border-radius: 5px;
}

/* Overriding style inside the main sider */

.main-sider.ant-layout-sider,
.main-sider.ant-layout-sider-trigger,
.main-sider.ant-layout-sider .ant-menu {
  background-color: #0061d4;
  color: #fff;
}

.main-sider.ant-layout-sider .ant-menu {
  border-right-color: #0061d4;
}

.main-sider.ant-layout-sider .ant-menu a,
.main-sider.ant-layout-sider .ant-tabs,
.main-sider.ant-layout-sider .ant-menu-submenu {
  color: #fff;
}

.main-sider.ant-layout-sider
  .ant-menu:not(.ant-menu-horizontal)
  .ant-menu-item-selected,
.main-sider.ant-layout-sider .ant-menu-item:active,
.main-sider.ant-layout-sider .ant-menu-submenu-title:active {
  background-color: #1d2037;
}

.main-sider.ant-layout-sider
  .ant-menu:not(.ant-menu-horizontal)
  .ant-menu-item-selected
  a,
.main-sider.ant-layout-sider .ant-menu-item:active a,
.main-sider.ant-layout-sider .ant-menu-submenu-title:active a,
.main-sider.ant-layout-sider .ant-tabs-nav .ant-tabs-tab-active,
.main-sider.ant-layout-sider .ant-tabs-nav .ant-tabs-tab:hover,
.main-sider.ant-layout-sider .ant-menu-submenu-selected,
.main-sider.ant-layout-sider .ant-menu-item:hover,
.main-sider.ant-layout-sider .ant-menu-item-active,
.main-sider.ant-layout-sider
  .ant-menu:not(.ant-menu-inline)
  .ant-menu-submenu-open,
.main-sider.ant-layout-sider .ant-menu-submenu-active,
.main-sider.ant-layout-sider .ant-menu-submenu-title:hover,
.main-sider.ant-layout-sider h4 {
  color: #fff;
}

.main-sider.ant-layout-sider .ant-menu-inline .ant-menu-item::after {
  /* border-right-width: 5px; */
  border-right: 3px solid #0061d4;
}

.main-sider.ant-layout-sider .ant-tabs-ink-bar {
  height: 3px;
}

.main-sider.ant-layout-sider .ant-tabs-bar {
  border-bottom-color: transparent;
}

.main-sider.ant-layout-sider .ant-tabs-nav-scroll {
  text-align: center;
}

.main-sider.ant-layout-sider .ant-tabs-nav .ant-tabs-tab {
  margin-right: 20px;
}
/* Logo, profile and footer links */
.logo {
  padding: 20px;
  z-index: 1;
}

.profile {
  padding: 20px;
  display: flex;
  align-items: center;
}

.profile .ant-avatar {
  margin-right: 20px;
}

.profile-links a {
  color: #fff;
}

.profile-links a .anticon {
  margin-right: 1em;
}

.sider-footer {
  padding: 0 20px;
  position: absolute;
  bottom: 0;
  width: 100%;
  color: #fff;
  font-size: 12px;
}

.sider-footer-links {
  display: flex;
  justify-content: space-between;
}

.main-wrapper {
  /* height: calc(100% - 70px);
    margin: 20px; */
  padding: 0 20px 20px;
  overflow: auto;
}

.react-grid-Container {
  max-width: 100%;
}

/* Header */

#header {
  position: relative;
  z-index: 10;
  max-width: 100%;
  background: #fff;
  -webkit-box-shadow: 0 2px 8px #f0f1f2;
  box-shadow: 0 2px 8px #f0f1f2;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

#header h2 {
  margin-bottom: 0;
}

.user-popover p:last-child {
  margin-bottom: 0;
}

/* Login form */

.ant-form.login-form {
  width: 350px;
  margin: auto;
}

.login-form-forgot {
  float: right;
}

/* Signup form */

.signup-form {
  width: 560px;
  height: 100%;
  max-width: calc(100% - 40px);
  margin: 80px auto;
}

.submitted-result {
  width: 480px;
  margin: auto;
}

.steps-actions {
  margin-top: 16px;
}
.ant-btn-primary {
  background-color: #0061d4 !important;
  border-color: #0061d4 !important;
}

.steps-actions .ant-btn-primary {
  float: right;
}

/* Reset password form */

.password-form {
  width: 300px;
  margin: auto;
}

/* Settings */

.list-actions {
  text-align: right;
}

.list-actions .ant-btn + .ant-btn {
  margin-left: 1em;
}

.ant-card + .ant-card {
  margin-top: 16px;
}

/* AddMultipleField */

.dynamic-field-row {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dynamic-field-row .dynamic-field {
  flex-grow: 1;
  margin-right: 8px;
}

.dynamic-field-row .anticon-minus-circle-o {
  margin-bottom: 24px;
}

.ant-form-horizontal .ant-input-number {
  width: 100%;
}

.unauth-wrapper {
  /* background-color: #22253f; */
  padding: 20px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  min-height: 100vh;
}

.unauth-content {
  background-color: #fff;
  width: 700px;
  height: auto;
  min-height: 760px;
  border-radius: 4px;
  z-index: 2;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.unauth-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
  text-align: right;
}

.unauth-bg .unauth-bg-img {
  width: 100%;
  height: 100%;
  /* opacity: 0.1; */
  background-image: url(../assets/Efiling_Background.jpg);
  background-size: cover;
}

.unauth-wrapper .logo {
  width: 700px;
  text-align: center;
  color: #fff;
}

.unauth-content-footer {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #9ba0b1;
}

.unauth-content-footer-links span + span {
  margin-left: 6px;
}

.unauth-content-header {
  text-align: right;
}

.unauth-content-header span + button {
  margin-left: 20px;
}

.form-header {
  margin-bottom: 24px;
}

.mentions {
  margin-bottom: 4px;
}

.mentions--multiLine .mentions__highlighter {
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 4px 11px;
  min-height: 92px;
  line-height: 1.5;
}

.mentions--multiLine .mentions__input {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 4px 11px;
  outline: 0;
  line-height: 1.5;
}

.mentions__input::placeholder {
  color: #bfbfbf;
}

.mentions__suggestions {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  line-height: 1.5;
  list-style: none;
  border-radius: 4px;
  outline: none;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.mentions__suggestions__list {
  max-height: 250px;
  overflow: auto;
  outline: none;
}

.mentions__suggestions__item {
  position: relative;
  display: block;
  min-width: 100px;
  padding: 5px 12px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
}

.mentions__suggestions__item:first-child {
  border-radius: 4px 4px 0 0;
}

.mentions__suggestions__item:last-child {
  border-radius: 0 0 4px 4px;
}

.mentions__suggestions__item--focused {
  background-color: #b3c7bb;
}

.mentions__mention {
  color: #0061d4;
  pointer-events: none;
}

.speed-dial .MuiFab-primary,
.speed-dial .MuiFab-primary:hover {
  background-color: #0061d4;
}

/* Editable row */

.editable-row .ant-form-explain {
  position: absolute;
  font-size: 12px;
  margin-top: -4px;
}

.editable-row .row-edit-actions .ant-btn-link {
  padding: 0;
}

.references-tooltip {
  max-width: 420px;
}

.ant-steps-vertical canvas {
  max-width: 72px;
  max-height: 72px;
}

.ant-steps-vertical .ant-steps-item {
  padding-bottom: 40px;
}

.ant-steps-vertical .ant-steps-item:last-child {
  padding-bottom: 0;
}

.ant-steps-vertical
  > .ant-steps-item
  > .ant-steps-item-container
  > .ant-steps-item-tail {
  left: 60px;
  padding-top: 125px;
}

.ant-steps-vertical .ant-steps-item-icon {
  width: 120px;
  margin-right: 46px;
}

.ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
  width: 120px;
  height: 120px;
  line-height: 120px;
  background-color: #f2f3f7;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.ant-popover {
  z-index: 1050;
}

.speed-dial .MuiSpeedDialAction-staticTooltipLabel {
  font-family: 'Overpass', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
}

.search-form {
  max-width: 1200px;
}

.search-form .ant-calendar-picker {
  width: 100%;
}

.setup-mfa-steps,
.reset-password-steps {
  margin: 30px 0 20px;
}

.setup-mfa-steps .ant-steps-item-icon,
.setup-mfa-steps .ant-steps-item-content {
  max-width: 170px;
}

.setup-mfa-steps.ant-steps-horizontal:not(.ant-steps-label-vertical)
  .ant-steps-item,
.reset-password-steps.ant-steps-horizontal:not(.ant-steps-label-vertical)
  .ant-steps-item {
  white-space: normal;
}

.pd-description {
  padding: 0 24px;
}

.pd-description .ant-descriptions-item-label {
  width: 200px;
}

.pd-description .ant-descriptions-item-content ul {
  padding-left: 20px;
  margin-bottom: 0;
}

.pd-description .ant-descriptions-item-content ul li + li {
  margin-top: 8px;
}

.m-r-5 {
  margin-right: 5px;
}

.d-flex {
  display: flex;
}
